import React from "react";
import { Container } from "react-bootstrap";
import { EDF, FORTUM, JSW, PERTONAS, SEREN, SUSTEN, TATA } from "../../assets/images";

const   Clients = () => {
  const client = [
    {
      image: EDF,
      alt: "clients-edf-logo",
    },
    {
      image: JSW,
      alt: "clients-jsw-logo",
    },
    {
      image: TATA,
      alt: "clients-fortum-logo",
    },
    {
      image: PERTONAS,
      alt: "clients-petronas-logo",
    },
    {
      image: SEREN,
      alt: "clients-logon-logo",
    },
  ];
  return (
    <div className="nimalaan-main-section" data-aos="fade-up" data-aos-anchor-placement="center-bottom" data-aos-duration="1000">
      <h2 className="nimalaan-section-title">
        We are proud <span>to have worked with</span>
      </h2>
      <Container>
        <div className="clients_card">
          <div className="clients_card_row">
            {client?.map((item, index) => {
              return (
                <div className="clients_card_col" key={item?.alt}>
                 <img className="img-fluid" src={item?.image} alt={item?.alt} />
                 </div>
              );
            })}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Clients;
