import { AboutusBanner, JoinNowImage, NimalaanBanner,ConatctUsImg, ServiceBannerImage, CARRIERBANNER, EVENTSBANNER } from "../assets/images";
import { ClientsBanner,AchevementsBanner,Mission_Banner} from "../assets/images";



export const homeBanner = {
  
    title : "Powering Tomorrow, Sustainably Today",
    content :"Explore our cutting-edge renewable energy solutions to reduce your carbon footprint and contribute to a sustainable world.",
    image: NimalaanBanner,
    alt:"imaalan-powering",
    joinnow:true,
    type:1,
}

export const joinBanner = {
  
    title : "Join Now! We would love to help you.",
    content :"Become a part of the renewable energy revolution with Nimalaan. Together, we can drive sustainable change and create a greener future. Join us today and be a part of our mission to harness the power of wind and solar energy for a better tomorrow",
    image: JoinNowImage,
    alt:"join-now-nimalaan",
    joinnow: false,
    type:2,

}

export const aboutBanner = {
  
    title : "Innovating for a Greener tomorrow",
    content :"At our company, we believe that together, we can make a difference. We are committed to making renewable energy accessible and affordable for all, so that we can create a cleaner, more sustainable future for generations to come",
    image: AboutusBanner,
    alt:"about-us-nimalaan",
    joinnow: false,
    type:2,
}
export const clientsBanner = {
  
    title : "Our Clients",
    content :"We are proud to partner with forward-thinking organizations committed to a sustainable future. Our clients trust us to deliver innovative and efficient renewable energy solutions, driving positive change and environmental stewardship.",
    image: ClientsBanner,
    alt:"join-now-nimalaan",
    joinnow: false,
    type:2,

}

export const AchievementsBanner = {
  
    title : "Achievements",
    content :"Our achievements reflect our commitment to excellence and sustainability. We are proud to have successfully developed and implemented numerous wind and solar power projects",
    image: AchevementsBanner,
    alt:"join-now-nimalaan",
    joinnow: false,
    type:2,

}
export const ServiceBanner = {
  
    title : " Nimalaan Energy service solutions",
    content :"We support companies and countries to reduce emissions across the energy landscape – for a more reliable, affordable, and sustainable energy system",
    image: ServiceBannerImage,
    alt:"Nimalaan Energy service solutions",
    joinnow: true,
    type:1,
    name : "services",
    col:7

}

export const MissionBanner = {
  
    title : "Empowering a sustainable world, one watt at a time ",
    content: [
        "We are committed to providing exceptional customer service and delivering high-quality results. We understand the unique challenges of wind and solar energy projects, and we work closely with our clients to ensure their projects are completed on time, within budget, and to the highest standards. ",
        "In the end, we will conserve only what we love; we will love only what we understand; and we will understand only what we are taught.", 
        "- Baba Dioum" 
    ],
    
    image: Mission_Banner,
    alt:"join-now-nimalaan",
    joinnow: false,
    type:3,
}

export const CarrierBanner = {
  
    title : "Join Our Team at Nimalaan",
    content :"We know that finding a meaningful and rewarding career can be a long journey. Our goal is to make that process easy for you and to create a work environment that's enriching—one that you'll look forward to every day.",
    image: CARRIERBANNER,
    alt:"join-now-nimalaan",
    joinnow: false,
    type:2,

}

export const EventsBanner = {
  
    title : "Events",
    content :"",
    image: EVENTSBANNER,
    alt:"join-now-nimalaan",
    joinnow: false,
    type:2,

}

export const ServiceData = [
    {
        box1:{
            data1 : "data",
            data2 : "data"
        },
        box2:{
            data1 : "data",
            data2 : "data"
        },
        box3:{
            data1 : "data",
            data2 : "data"
        },
        box4:{
            img:"image1.png"
        },
        box5:{
            data1 : "data",
            data2 : "data"
        },
        box6:{
            data1 : "data",
            data2 : "data"
        },
        box7:{
            data1 : "data",
            data2 : "data"
        },


    }
]

export const ContactBanner = {
  
    title : "Contact Us",
    content :"If you are looking for a partner to help you successfully assess and develop your wind or solar energy project, look no further. Contact us today to learn more about our comprehensive project management solutions.",
    image: ConatctUsImg,
    alt:"Conatct-us-nimalaan",
    joinnow: false,
    type:2,
  

}