import React from 'react';
import { Container } from 'react-bootstrap';
import './Achievementslist.css';

const AchievementsList = ({ data }) => {
    return (
        <Container className="h-100 mt-130">
            <div className="achievements-container" data-aos="fade-down" data-aos-duration="1000">
                <div className="row justify-center">
                    {data.map((achievement, index) => (
                        <div key={index} className={`col-md-4 achievement-div`}>
                            <div className="achievement-item ps-4 pt-4 pb-4 col-md-4">
                                <p className="achievement-title">{achievement.title}</p>
                                <p className="achievement-project">{achievement.Project}</p>
                                <p className="achievement-content">{achievement.content}</p>
                                {achievement.windproject && <p className="wind-project">Wind Project,<span> Tamilnadu</span></p>}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </Container>
    );
}

export default AchievementsList;
