import React, { useEffect } from "react";
import { motion } from "framer-motion";
import Banner from "../../components/banner/Banner";
import Header from "../../components/header/Header";
import Clients from "../../components/clients-section/Clients";
import Innovation from "../../components/innovation/Innovation";
import OurService from "../../components/our-service/OurService";
import OurMission from "../../components/our-mission/OurMission";
import TeamExpertise from "../../components/team-expertise/TeamExpertise";
import OurAchievements from "../../components/our-achievements/OurAchievements";
import ContactInfo from "../../components/contact-info/ContactInfo";
import HeaderSeo from "../../components/head-seo/HeaderSeo";
import Footer from "../../components/footer/Footer";
import { homeBanner } from "../../utils/Banner";
import { HomeSeo } from "../../utils/Seo";


const Home = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
    >
      <HeaderSeo data={HomeSeo} />
      <Header />
      <Banner data={homeBanner} />
      <Clients />
      <Innovation />
      <OurService />
      <OurMission />
      <TeamExpertise />
      <OurAchievements />
      {/* <ContactInfo /> */}
      <Footer />
    </motion.div>
  );
};

export default Home;
