import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { IoCallOutline } from "react-icons/io5";
import { Location, Mail } from "../../assets/svg";
const ContactInfo = () => {
  return (
    <div className="nimalaan-main-section mt-50 ">
      {/* <h2 className="contact_title" >Contact Information</h2> */}
      <div className="section-sub-container">
        <Container>
          {/* <Row >
            <Col  lg={{ span: 6, offset: 3 }}>
              <p className="mt-1 text-center" >
              If you are looking for a partner to help you successfully assess and develop your wind or solar energy project, look no further. Contact us today to learn more about our comprehensive project management solutions.
              </p>
            </Col>
          </Row> */}
          <Row className="mt-45 ">
            <Col className="pd-0_8 contact_info" xs={12} md={4} sm={6}>
              <div className="contact_card" >
                <div className="text-center">
                  <IoCallOutline style={{ color: "#166CBF" }} />
                  <p>Phone Number</p>
                  <h5>+91-94444 44832 | +91-98419 23999</h5>
                </div>
              </div>
            </Col>
            <Col className="pd-0_8 contact_info"  xs={12} md={4} sm={6}>
              <div className="contact_card" >
                <div className="text-center">
                  <Mail />
                  <p>Email</p>
                  <h5>info@nimalaanenergies.com</h5>
                  <h5>nimalaanenergiesllp@gmail.com</h5>
                </div>
              </div>
            </Col>
            <Col   xs={12} md={4}>
              <div className="contact_card" >
                <div className="text-center" >
                  <Location />
                  <p>Address</p>
                  <h5>
                  2nd Floor, No. 152, Maruthi Complex, Jawaharlal Nehru Street, Arumbakkam, Chennai - 600106, Tamil Nadu 
                  </h5>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default ContactInfo;
