import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import ContactInfo from "../../components/contact-info/ContactInfo";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import HeaderSeo from "../../components/head-seo/HeaderSeo";
import BreadCrumbCustom from "../../components/Breadcrumb/BreadCrumbCustom";
import CarrierBanner from "../../components/Carrier-banner/CarrierBanner";
import JobView from "../../components/job-view/JobView";
import PageLoader from "../../components/page-loader/PageLoader";
import axiosInstance from "../../api/api";
import { CareerDetailsSeo } from "../../utils/Seo";

const CarrierDetail = () => {
  const { id } = useParams();
  const [items, setItems] = useState();
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    setLoader(true);
    const fetchData = async () => {
      try {
        const res = await axiosInstance.get(`/jobs/web/${id}`);
        if (res?.data?.status != true) {
          setLoader(false);

          navigate("/404-not-found", { replace: true });
        } else {
          setItems(res?.data?.data);
          setLoader(false);
        }
      } catch (e) {
        setLoader(false);
      }
    };
    fetchData();
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
    >
      <HeaderSeo data={CareerDetailsSeo} />
      <Header />
      <BreadCrumbCustom
        mainLink={"/careers"}
        mainTitle={"Job List"}
        secondLink={"/careers/careers-detail"}
        secondTitle={"Job Details"}
      />
      <CarrierBanner
        Height={"305"}
        btn={true}
        JobShift={items?.job_shift.shiftName}
        JobPosition={items?.jobTitle}
        id={items?.id}
      />
      <JobView viewData={items} />
      {/* <ContactInfo /> */}
      <Footer />
      {loader == true ? <PageLoader /> : null}
    </motion.div>
  );
};

export default CarrierDetail;
