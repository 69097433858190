import React from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row, Card } from "react-bootstrap";
import { RMC, EDF_PROJECT, JSW_KARNATAKA } from "../../assets/images";


const OurProject = ({marginTopRemove}) => {
  const navigate = useNavigate()
 
  return (
    <div className="our-projects">
      <div className="nimalaan-main-section" >
        <Container>
          <h1>Our Projects</h1>
        <Card className="mb-4 client-card bg-white"  data-aos="fade-right" data-aos-duration="1000">
            <Row className='d-flex'>
              <Col lg={4} md={4}>

                <Card.Img src={RMC} alt={'RMC'} className='client-card-image' height="300px" />
                </Col>
                <Col lg={8} md={8} className="">
                <div className="p-20">
                <h3>For JSW Energy</h3>
                <p className="nimalaan-section-subtitle">Dharapuram, Tamil Nadu</p>
                <ul className="pl-20 mt-15 lh-32">
                    <li><b>RMC Supply:</b> Supply of Ready-Mixed Concrete (RMC) for the construction of foundations for 50 Wind Turbine Generators (WTGs).
                    </li>
                    <li><b>WTG Foundation Work:</b> Execution of foundation construction work for 50 WTGs</li>
                    <li><b>SVG Station Construction:</b> Construction of two Static Var Compensator (SVG) stations with a capacity of 25 MW each and one SVG station with a capacity of 50 MW.
                    </li>
                </ul>
                </div>
                
              </Col>
            </Row>
            </Card>
            <Card className="mb-4 client-card bg-white" data-aos="fade-right" data-aos-duration="1000">
            <Row className='d-flex'>
              <Col lg={4} md={4}>

                <Card.Img src={EDF_PROJECT} alt={'RMC'} className='client-card-image' height="300px" />
                </Col>
                <Col lg={8} md={8}>
                <div className="p-20">
                <h3>For EDF Energy</h3>
                <p className="nimalaan-section-subtitle">Gadag, Karnataka</p>
                <ul>
                  <li>Site location identification and selection, thorough due diligence and risk assessment, successful negotiation and acquisition of land, land assembly and consolidation with agreement to sale, and finally, the precise marking of boundary stones. </li>
                </ul>
                </div>
               
              </Col>
            </Row>
            </Card>
            <Card className="client-card bg-white" data-aos="fade-right" data-aos-duration="1000">
            <Row className='d-flex'>
              <Col lg={4} md={4} >

                <Card.Img src={JSW_KARNATAKA} alt={'RMC'} className='client-card-image' height="300px" />
                </Col>
                <Col lg={8} md={8}>
                <div className="p-20">
                <h3>For JSW Energy</h3>
                <p className="nimalaan-section-subtitle">Davanagere, Karnataka</p>
                <ul>
                  <li>Site location identification and selection, thorough due diligence and risk assessment, successful negotiation and acquisition of land, land assembly and consolidation with agreement to sale, and finally, the precise marking of boundary stones. </li>
                </ul>
                </div>
               
              </Col>
            </Row>
          </Card>
          {/* <Row className="oreder-change-row our-project">
            <Col md={4} lg={4}>
              <div className="our-service-card" data-aos="fade-up" data-aos-duration="1000">
                <img
                  className="img-fluid"
                  src={RMC}
                  alt="feasibility-analysis-for-wind-and-solar-sites"
                  width={'100%'}
                  height="250px"
                />
                <div className="our-service-card-content ">
                  <h3>RMC Supply</h3>
                  <ul className="pl-20 mt-15">
                    <li>Supply of Ready-Mixed Concrete (RMC) for the construction of foundations for 50 Wind Turbine Generators (WTGs).
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col md={4} lg={4}>
              <div className="" data-aos="fade-up" data-aos-duration="1300">
                <h2 className="nimalaan-section-title withcard">
                  Our Projects
                </h2>
                <div className="text-center">
                  <p className="nimalaan-section-subtitle">For JSW energy <br></br>

Dharapuram, Tamil Nadu
                  </p>
                </div>

                <div className="our-service-card mt-75">
                  <img
                    className="img-fluid"
                    src={CivilWork}
                    alt="permits-and-approvals"
                    width={'100%'}
                    height="250px"
                  />
                  <div className="our-service-card-content ">
                    <h3>WTG Foundation Work</h3>
                    <ul className="pl-20 mt-15">
                      <li>Execution of foundation construction work for 50 WTGs </li>
                    
                    </ul>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={4} lg={4}>
              <div className="our-service-card" data-aos="fade-up" data-aos-duration="1200">
                <img className="img-fluid" src={SVG} alt="LandSouce" width={'100%'}
                    height="250px"/>
                <div className="our-service-card-content">
                  <h3>SVG Station Construction</h3>
                  <ul className="pl-20 mt-15">
                    <li>
                    Construction of two Static Var Compensator (SVG) stations with a capacity of 25 MW each and one SVG station with a capacity of 50 MW.
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row> */}
        </Container>
      </div>
    </div>
  );
};

export default OurProject;
