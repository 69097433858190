
export const Achievements_List = [
    {
  
    title : "+100 LOCATIONS",
    Project : "FOUNDATION WORK",
    content : "Established RMC Plant for foundation work for JSW",
    windproject: false
},
{
  
    title : "200MW",
    Project : "IPP",
    content : "Acquired evacuation license in Ottapidaram",
    windproject: false
},
{
  
    title : "1500+ MV",
    Project : "LIASONING",
    content : "Facilitated Wind and Solar Project Government Orders from the state of Karnataka",
    windproject: false
},
{
  
    title : "1500+MW",
    Project : "LAND SOURCING",
    content : "Wind and Solar Project in Tamil Nadu, Karnataka, Andhra Pradesh, Maharashtra",
    windproject: false
},
// {
  
//     title : "300+ MW",
//     Project : "Liasoning",
//     content : "",
//     windproject: true
// },
// {
  
//     title : "50+ MW",
//     Project : "Land Sourcing",
//     content : "",
//     windproject: true
// }

]