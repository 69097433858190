import { Container, Navbar, Nav, Offcanvas } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { NimalaanLogo } from "../../assets/images";

const Header = () => {
  const navigate = useNavigate();

  return (
    <div style={{backgroundColor:'#F8FFF6'}} className="nimalaan_custom_nav_bg">
    <Navbar
      id="bg-transparent navbar-example2"
      expand="lg"
      className=" nimalaan_custom_nav"
    >
      <Container className="navber_custom">
        <NavLink to="/">
          <img
            className="nimalaan-logo img-fluid"
            src={NimalaanLogo}
            alt="nimalaan-energies-logo"
          />
        </NavLink>
        <Navbar.Toggle aria-controls="offcanvasNavbar-expand-lg" />
        <Navbar.Offcanvas
          id="offcanvasNavbar-expand-lg"
          aria-labelledby="offcanvasNavbarLabel-expand-lg"
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id="offcanvasNavbarLabel-expand-lg">
            <NavLink to="/">
          <img
            className="nimalaan-logo img-fluid"
            src={NimalaanLogo}
            alt="nimalaan-energies-logo"
          />
        </NavLink>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-center flex-grow-1 pe-3">
            <NavLink className="nav-link" to="/about">
                About
              </NavLink>
              <NavLink className="nav-link" to="/services">
                Services
              </NavLink>
              <NavLink className="nav-link" to="/clients">
                Clients
              </NavLink>
              <NavLink className="nav-link" to="/achievements">
                Achievements
              </NavLink>
              <NavLink className="nav-link" to="/mission">
                Mission
              </NavLink>
              <NavLink className="nav-link" to="/careers">
              Careers 
              </NavLink>
              <NavLink className="nav-link" to="/events">
              Events
              </NavLink> 
              <NavLink className="nav-link" to="/contact-us">
                Contact
              </NavLink>
            </Nav>

            <button
              className="primary-btn navbar-join"
              onClick={() => {
                navigate("/join-now");
              }}
            >
              Join now
            </button>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
    </div>
  );
};

export default Header;
