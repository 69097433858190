import React from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { Feasbility, LandSouce, Permits } from "../../assets/images";


const OurService = ({marginTopRemove}) => {
  const navigate = useNavigate()
 
  return (
    <div>
      <div className={marginTopRemove ?  "nimalaan-main-section"  : "nimalaan-main-section mt-130"} >
        <Container>
          <Row className="oreder-change-row">
            <Col md={4} lg={4}>
              <div className="our-service-card" data-aos="fade-up" data-aos-duration="1000">
                <img
                  className="img-fluid"
                  src={Feasbility}
                  alt="feasibility-analysis-for-wind-and-solar-sites"
                />
                <div className="our-service-card-content ">
                  <h3>Feasibility Analysis for Wind and Solar Sites</h3>
                  <ul className="pl-20 mt-15">
                    <li>
                      Identifying Potential Sites for Wind & Solar Energy
                      Projects
                    </li>
                    <li>Wind Resource Analysis, Development & Micrositing</li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col md={4} lg={4}>
              <div className="" data-aos="fade-up" data-aos-duration="1300">
                <h2 className="nimalaan-section-title withcard">
                  Our Services
                </h2>
                <div className="text-center">
                  <p className="nimalaan-section-subtitle">
                    With A Progressive Approach To Let The World Embrace
                    Sustainable Energy
                  </p>
                  <button className="primary-btn "  onClick={() => { navigate("/join-now")}}>Join now</button>
                </div>

                <div className="our-service-card mt-75">
                  <img
                    className="img-fluid"
                    src={Permits}
                    alt="permits-and-approvals"
                  />
                  <div className="our-service-card-content ">
                    <h3>Permits and Approvals</h3>
                    <ul className="pl-20 mt-15">
                      <li>Acquiring Required Permits & Approvals</li>
                      <li>Study and Approval for Power Evacuation</li>
                      <li>
                        Support for Execution of Power Purchase Agreement{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={4} lg={4}>
              <div className="our-service-card" data-aos="fade-up" data-aos-duration="1200">
                <img className="img-fluid" src={LandSouce} alt="LandSouce" />
                <div className="our-service-card-content ">
                  <h3>Land Sourcing</h3>
                  <ul className="pl-20 mt-15">
                    <li>
                      Document verification, Due Diligence and Land Aggregation
                    </li>
                    <li>Land acquisition support</li>
                    <li>
                      Surveying, levelling and road infrastructure development
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default OurService;
