export const NotFundImg = require("./error.png")
export const NimalaanLogo = require('./nimalaan-energies.png')
export const NimalaanBanner = require('./nimaalan-powering.png')
export const ClientsBanner = require('./clients-banner.png')
export const Mission_Banner = require('./mission-banner.png')
export const AchevementsBanner = require('./Achievements-banner.png')
export const EDF = require('./edf-logo.png')
export const JSW = require('./jsw-logo.png')
export const FORTUM = require('./fortum-logo.png')
export const PERTONAS = require('./petronas-logo.png')
export const SUSTEN = require('./susten-logon.png')

export const Clients_EDF = require('./edf.png')
export const Clients_JSW = require('./jsw.png')
export const Clients_FORTUM = require('./fortum.png')
export const Clients_msolar = require('./m+ solar.png')
export const Clients_SUSTEN = require('./susten.png')

export const RENEWABLE_ENERGY = require('./turnkey-development-of-renewable-energy-projects.png')
export const  Feasbility = require ('./feasibility-analysis-for-wind-and-solar-sites.png')
export const  Permits = require ('./permits-and-approvals.png')
export const  LandSouce = require ('./land-sourcing.png')
export const  CivilWork = require ('./civil-work.jpg')
export const  RMC = require ('./rmc.png')
export const  SVG = require ('./svg.png')
export const  EDF_PROJECT = require ('./edf-project.png')
export const  JSW_KARNATAKA = require ('./jsw-karnataka.png')

export const  Empowering = require ('./empowering-a-sustainable-world-1.png')
export const  Empowering1 = require ('./empowering-a-sustainable-world-2.png')

export const OurPresence = require('./our-presence-is-in-india.png')
export const OurPresenceImg = require('./our-presence-is-in-india.png')
export const OurAchevements_img = require('./our-achievements.png')

export const FooterLogo = require('./footer-logo.png')

export const JoinNowImage = require('./join-now.png')
export const AboutusBanner = require('./nimalaan-aboutus.png')
export const OurHistoryImg=require('./ourhistory.png')


export const CeoImage=require('./ceo.png')
export const ManagerImage=require('./manager.png')
export const CoFounderImage=require('./cofounder.png')
export const VicePresidentImage=require('./vicepresident.png')

export const WhatWeDoImg=require('./whatwedo.png')
export const WhyNimalaanImg=require('./whynimalaan.png')

export const CoreTeam=require('./CoreTeam.png')
export const Technicalteam=require('./Technicalteam.png')
export const Serviceteam=require('./Serviceteam.png')

export const ServiceBannerImage = require("./nimalaan-energy service-solutions.webp");
export const Feasibility = require("./feasibility-analysis.webp")
export const LandSouring = require("./land-sourcing.webp")
export const PermitsandApporoves = require("./permits-approvals.webp")

export const ConatctUsImg = require('./nimalaan-contact-us.webp')

export const UPLOADER = require('./uploader.png')

export const CARRIERBANNER = require('./ninmaalan-carriers.png')

export const EVENTSBANNER = require('./events-banner.png')
export const EVENTS_1 = require('./event-1.png')
export const EVENTS_2 = require('./event-2.png')
export const EVENTS_3 = require('./event-3.png')
export const EVENTS_4 = require('./event-4.png')
export const EVENTS_5 = require('./event-5.png')
export const EVENTS_6 = require('./event-6.png')
export const EVENTSDETAILBANNER = require('./eventdetail-banner.png')
export const EVENTGALLERY_1 = require('./evn-gallery-1.png')
export const EVENTGALLERY_2 = require('./evn-gallery-2.png')
export const EVENTGALLERY_3 = require('./evn-gallery-3.png')
export const EVENTGALLERY_4 = require('./evn-gallery-4.png')
export const EVENTGALLERY_5 = require('./evn-gallery-5.png')
export const EVENTGALLERY_6 = require('./evn-gallery-6.png')
export const EVENTGALLERY_7 = require('./evn-gallery-7.png')
export const EVENTGALLERY_8 = require('./evn-gallery-8.png')

export const LOADINGGIF = require("./loading.gif")

export const NOJOBS = require("./no_jobs.png")
export const NO_JOBS = require('./no-jobs.png')
export const NO_EVENTS = require('./no-events.png')


export const IMG_PLACEHOLDER = require('./image-placeholder.png')
export const NO_GALLARY = require('./no-gallary-img.png')


export const    DIRECTER = require('./directer.jpg')
export const    TATA = require('./Picture2.png')
export const    SEREN = require('./Picture3.jpg')
