import React from 'react';
import { Col, Container, Row, Card } from 'react-bootstrap';
import './Clients-List.css';
import { EDF, FORTUM, JSW, PERTONAS, SEREN, SUSTEN, TATA } from '../../assets/images';
const ClientsList = ({ data }) => {
     const client = [
        {
          image: EDF,
          alt: "clients-edf-logo",
        },
        {
          image: JSW,
          alt: "clients-jsw-logo",
        },
        {
          image: TATA,
          alt: "clients-fortum-logo",
        },
        {
          image: PERTONAS,
          alt: "clients-petronas-logo",
        },
        {
          image: SEREN,
          alt: "clients-logon-logo",
        },
      ];
    return (
         <Container>
              <div className="clients_card_detail" data-aos="fade-right" data-aos-duration="1000">
                <div className="clients_card_row_detail">
                  {client?.map((item, index) => {
                    return (
                      <div className="clients_card_col_detail" key={item?.alt}>
                       <img className="img-fluid" src={item?.image} alt={item?.alt} />
                       </div>
                    );
                  })}
                </div>
              </div>
            </Container>
    );
}
export default ClientsList;