import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import ContactInfo from "../../components/contact-info/ContactInfo";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import HeaderSeo from "../../components/head-seo/HeaderSeo";
import BreadCrumbCustom from "../../components/Breadcrumb/BreadCrumbCustom";
import ApplyNow from "../../components/Apply-now/ApplyNow";
import CarrierBanner from "../../components/Carrier-banner/CarrierBanner";
import axiosInstance from "../../api/api";
import { CareerApplySeo } from "../../utils/Seo";

const CarrierApply = () => {
  const { id } = useParams();
  const [items, setItems] = useState();
  const [loader, setLoader] = useState(false);
  const [url, serUrl] = useState("");

  useEffect(() => {
    setLoader(true);
    const fetchData = async () => {
      try {
        const res = await axiosInstance.get(`/jobs/web/${id}`);
        if (res?.data?.status != true) {
          setLoader(false);
        } else {
          setItems(res?.data?.data);
          setLoader(false);
          generateUrl(res?.data?.data?.jobTitle);
        }
      } catch (e) {
        setLoader(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const generateUrl = (name) => {
    const urlFriendlyName = name
      .toLowerCase()
      .replace(/[^\w\s-]/g, "")
      .replace(/\s+/g, "-")
      .replace(/^-+/g, "")
      .replace(/-+$/g, "");

    serUrl(urlFriendlyName);

    return urlFriendlyName;
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
    >
      <HeaderSeo data={CareerApplySeo} />
      <Header />
      <BreadCrumbCustom
        mainLink={"/careers"}
        mainTitle={"Job List"}
        secondLink={`/careers/careers-detail/${url}/${id}`}
        secondTitle={"Job Details"}
        thirdTitle={`Apply`}
      />
      <CarrierBanner
        btn={false}
        JobShift={items?.job_shift.shiftName}
        JobPosition={items?.jobTitle}
        id={items?.id}
      />
      <ApplyNow />
      {/* <ContactInfo /> */}
      <Footer />
    </motion.div>
  );
};

export default CarrierApply;
