import React, { useEffect } from "react";
import { motion } from "framer-motion";
import Header from "../../components/header/Header";
import HeaderSeo from "../../components/head-seo/HeaderSeo";
import Banner_2 from "../../components/banner/Banner_2";
import AchievementsList from "../../components/Achievements/Achievementslist";
import OurProject from "../../components/our-project/OurProject";
import ContactInfo from "../../components/contact-info/ContactInfo";
import Footer from "../../components/footer/Footer";
import { Achievements_List } from "../../utils/Achevementslist";
import { AchievementsBanner } from "../../utils/Banner";
import { AchievementsSeo } from "../../utils/Seo";

const Achievements = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
    >
      <HeaderSeo data={AchievementsSeo} />
      <Header />
      <Banner_2 data={AchievementsBanner} />
      <AchievementsList data={Achievements_List} />
      <OurProject />
      {/* <ContactInfo /> */}
      <Footer />
    </motion.div>
  );
};

export default Achievements;
