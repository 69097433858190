import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { WhyNimalaanImg } from "../../assets/images";

const WhyNimalaan = () => {
  const firstRow = [
    {
      subtitle: "Expertise",
      content:
        "With years of experience in the renewable energy sector, Nimalaan brings unparalleled knowledge and proficiency to every project, ensuring optimal outcomes",
    },
    {
      subtitle: "Innovative Solutions",
      content:
        "We continuously explore and implement innovative approaches to optimize project timelines, reduce costs, and maximize project returns",
    },
    {
      subtitle: "Client-Centric Approach",
      content:
        "We prioritize open communication, transparency, and building long-term partnerships with our clients",
    },
    {
      subtitle: "Proven Track Record",
      content:
        "We have a successful history of delivering successful projects, exceeding client expectations and contributing to the growth of the renewable energy sector.",
    }
  ];


  return (
    <div className="nimalaan-main-section card_sec mt-130">
      <Container>
        <Row>
          <Col md={12} lg={7} data-aos="fade-down" data-aos-duration="1000">
            <div>
              <h2 className="image-content-card">Why Nimalaan</h2>
              {/* <p className="image-card-content">
                Collaboration is central to how we work. We combine our
                technology and talents to deliver better outcomes for
                people and the planet. With 40 years of experience and 
                over 4,500 people employed around the world, we’re able to put
                our expertise to good 
                use and power positive change.
              </p> */}

              <Row className="mt-4">
                {firstRow.map((item, index) => {
                  return (
                    <Col key={item?.title} md={6} lg={6} className="mt-2 mb-3">
                      <div className="achievement_card">
                        <h3 className="achievement_card_title">{item?.title}</h3>
                        <h4 className="achievement_card_subtitle">
                          {item?.subtitle}
                        </h4>
                        <p className="achievement_card_content">
                          {item?.content}
                        </p>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </Col>
          <Col md={12} lg={5} data-aos="fade-down" data-aos-duration="1000">
            <img
              className="img-fluid h-100 service pl-50"
              src={WhyNimalaanImg}
              alt="why nimalaan"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default WhyNimalaan;
