import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import {
  CeoImage,
  CoFounderImage,
  VicePresidentImage,
  ManagerImage,
  DIRECTER,
} from "../../assets/images";
import "./OurTeam.css";

const OurTeam = () => {
  const profile = [
    {
      name: "Saurabh Singh",
      img: CeoImage,
      designation: "CEO & DIRECTOR",
    },
    {
      name: "Prateek Saxena",
      img: CoFounderImage,
      designation: "DIRECTOR & CO-FOUNDER",
    },
    {
      name: "Rajat Singh",
      img: VicePresidentImage,
      designation: "VP- SERVICE DELIVERY",
    },
    {
      name: "Apeksha Mehta",
      img: ManagerImage,
      designation: "MANAGER TECHNOLOGY",
    },
  ];
  return (
    <div className="nimalaan-main-section mt-10">
      <h2 className="nimalaan-section-title" data-aos="fade-up" data-aos-duration="300">Our Core Team Leaders</h2>
      <div className="innovation-content">
        <Container>
          <Row data-aos="fade-right" data-aos-duration="1000">
            <Col lg={{ span: 8, offset: 2 }}>
              <p className="mt-1 text-center text-information">
                The Management team comprises of professionals with a wealth of cross functional expertise within the renewable energy business value chain with a well-rounded business vision, which augers well from a scalability and sustainability perspective
              </p>
            </Col>
          </Row>
          <Card className="p-4 client-card team-leader">
            <div className='d-flex'>
              <div className="img-sec" data-aos="fade-right" data-aos-duration="1000">

                <Card.Img src={DIRECTER} alt={'DIRECTER'} className=''  />
                </div>
                <div data-aos="fade-right" data-aos-duration="1000">
                <Card.Body className='mt-3'>
                  <Card.Title className='client-card-title'>R. Subbiah - Director
                  </Card.Title>
                  <Card.Text className='client-card-content mt-3'>
                    Subbiah holds a Diploma in civil engineering from Tamilnadu Polytechnic University who is a seasoned and well-connected professional within renewable energy sector since 1995. Previously associated with Windia Power, NEG Micon,  Vestas and Enercon driving their land, permitting and govt. relation functions
                  </Card.Text>
                </Card.Body>
              </div>
            </div>
          </Card>
          {/* <Row className="mt-45" data-aos="fade-right" data-aos-duration="1000">
            
              {profile.map((item, index) => (
                <Col xs={6} md={3} lg={3}>
                  <div className="our-team-card text-cente">
                    <img
                      className="img-fluid"
                      src={item?.img}
                      alt={item?.name}
                    />
                    <div className="our-team-card-content ">
                      <h3>{item?.name}</h3>
                      <p>{item?.designation}</p>
                    </div>
                  </div>
                </Col>
              ))}
            
          </Row> */}
        </Container>
      </div>
    </div>
  );
};

export default OurTeam;
