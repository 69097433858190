import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { motion } from "framer-motion";
import ContactInfo from "../../components/contact-info/ContactInfo";
import Footer from "../../components/footer/Footer";
import HeaderSeo from "../../components/head-seo/HeaderSeo";
import Header from "../../components/header/Header";
import Banner_2 from "../../components/banner/Banner_2";
import JobCard from "../../components/Jobcard/JobCard";
import { CarrierBanner } from "../../utils/Banner";
import { CareersSeo } from "../../utils/Seo";
const Carrier = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
    >
      <HeaderSeo data={CareersSeo} />
      <Header />
      <Banner_2 data={CarrierBanner} />
      <Container>
        <JobCard />
      </Container>
      {/* <ContactInfo /> */}
      <Footer />
    </motion.div>
  );
};

export default Carrier;
