import React, { useEffect } from 'react'
import { motion } from "framer-motion";
import ContactInfo from '../../components/contact-info/ContactInfo';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import HeaderSeo from "../../components/head-seo/HeaderSeo";
import EventDetail from '../../components/Event-Detail/EventDetail';
import { EventDetailsSeo } from "../../utils/Seo";

const EventsDetails = () => {

    useEffect(() => {
        window.scroll({
            top: 0,
            behavior: "smooth",
        });
    }, []);

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 1 } }}>
             <HeaderSeo data={EventDetailsSeo} />
             <Header />
             <EventDetail />
             {/* <ContactInfo /> */}
            <Footer />
        </motion.div>
    )
}

export default EventsDetails