import React, { useEffect } from "react";
import { motion } from "framer-motion";
import Header from "../../components/header/Header";
import HeaderSeo from "../../components/head-seo/HeaderSeo";
import Banner_2 from "../../components/banner/Banner_2";
import JoinNowForm from "../../components/join-now-form/JoinNowForm";
import ContactInfo from "../../components/contact-info/ContactInfo";
import Footer from "../../components/footer/Footer";
import { joinBanner } from "../../utils/Banner";
import { JoinSeo } from "../../utils/Seo";

const JoinNow = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
    >
      <HeaderSeo data={JoinSeo} />
      <Header />
      <Banner_2 data={joinBanner} />
      <JoinNowForm />
      {/* <ContactInfo /> */}
      <Footer />
    </motion.div>
  );
};

export default JoinNow;
