import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { OurHistoryImg } from "../../assets/images";
import "./ourhistory.css";

const steps = [
  {
    label: "2019",
    heading: "Nimalaan is founded",
    description:
      "Foundation of the group laid by incorporation of Nimalaan Energies LLP",
  },
  {
    label: "2020",
    heading: "Nimalaan is founded",
    description: `Secured Project Allotment for 132MW for EDF Renewables`,
  },
  {
    label: "2021",
    heading: "Nimalaan is founded",
    description: `Secured repeat order for 460MW and completed land acquisition for 10 WTG locations for EDF Renewables`,
  },
  {
    label: "2022",
    heading: "Nimalaan is founded",
    description: `Completed the entire land acquisition for the EDF Renewables project`,
  },
  {
    label: "2023",
    heading: "Nimalaan is founded",
    description: `Ventured into civil infrastructure  & commissioned RMC plant,  secured RMC supply contract for RMC from JSW. Secured Grid Connectivity  of 400MW in MH for Tata Power, worked for Sarens as intercarting & erection support provider. Secured 379MW solar project for land and permitting scope from Tata Power scope in KA`,
  },
  {
    label: "2024",
    heading: "Nimalaan is founded",
    description: `Secured Project Allotment for 379MW solar in KA &  repeat order for 302MW wind project in TN from Tata Power. Secured new business of 150MW from Amplus in TN & tied-up infrastructure development capabilities`,
  },
];

const OurHistory = () => {
  const [activeStep, setActiveStep] = React.useState(0);

  return (
    <div
      className="nimalaan-main-section mt-130 mb-4"
      style={{ backgroundColor: "white" }}
    >
      <Container>
        <Row>
          <h2 className="history-card-main" data-aos="fade-up" data-aos-duration="300">Our History</h2>
          <Col lg={6} md={12} data-aos="fade-right" data-aos-duration="1000">
            <Row className="d-flex">
              <Col lg={4} md={12} className="stepperdir mt-30">
                <div className="team-expertise">
                  <Box sx={{ maxWidth: 300 }}>
                    <Stepper activeStep={activeStep} orientation="vertical">
                      {steps.map((step, index) => (
                        <Step
                          key={step.label}
                          sx={{
                            "& .MuiStepLabel-labelContainer":{
                              color:'#000000',
                              fontFamily:'var(--heebo)'
                            },
                            "& .MuiStepLabel-root .Mui-completed": {
                              color: "#000000"
                            },
                            "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                              {
                                color: "blue",
                              },
                            "& .MuiStepLabel-root .Mui-active": {
                              color: "#1A6CB6",
                              fontSize:'25px',
                              lineHeight:'26px',
                              fontFamily:'var(--heebo)',
                              marginX:'-1px'
                            },
                            "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                              {
                                color: "#000000",
                              },
                            "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text":
                              {
                                fill: "white",
                              },
                            ".MuiSvgIcon-root.Mui-active": {
                              color: "#1A6CB6",
                              padding: "-3px",
                              borderRadius: "50%",
                              marginY: "-3px",
                            },
                            ".Mui-active .MuiStepIcon-text": {
                              fill: "white",
                            },
                          }}
                          className="stepnone"
                        >
                          <StepLabel onClick={() => setActiveStep(index)} >
                            {step.label}
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Box>
                </div>
              </Col>

              <Col lg={12} md={12} xs={9} className="mt-30" style={{flexBasis:"80%"}}>
                <Box sx={{ mb: 2 }}>
                  <Paper
                    square
                    elevation={0}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      height: 50,
                      pl: 2,
                    }}
                  >
                    <Typography className="historylabel">
                      {steps[activeStep].label}
                    </Typography>
                  </Paper>
                  <h3 className="achievement_card_subtitle historyheading history_title mt-30">
                    {steps[activeStep].heading}
                  </h3>
                  <Box sx={{ height: 400, maxWidth: 400, width: "80%", p: 2 }}>
                    {steps[activeStep].description}
                  </Box>
                </Box>
              </Col>
            </Row>
          </Col>

          <Col lg={6} md={12} data-aos="fade-right" data-aos-duration="1000">
            <div className="map_card our_history">
              <img
                className="img-fluid"
                src={OurHistoryImg}
                alt="our-history-img"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OurHistory;
