import React from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { CivilWork } from "../../assets/images";
import { height } from "@mui/system";


const ServicePageCard = () => {
  const navigate = useNavigate();
  return (
    <div className="nimalaan-main-section">
     

       {/* land source  */}

       <div className="mt-130">
      <h2 className="nimalaan-section-title" data-aos="fade-down" data-aos-duration="500">
      Civil Work
      </h2>
      <div className="section-sub-container" >
        <Container>
                
          <div className="service_page_card">
            <div className="service_page_card_content">
              <div className="service_page_card_small " data-aos="fade-right" data-aos-duration="1000">
                <h2>WTG Foundation</h2>
                <p>A robust foundation for Wind Turbine Generators (WTGs) is essential for ensuring both the longevity and performance of your project. Our team of engineers meticulously designs and constructs foundations tailored to the unique conditions of each site. This customized approach guarantees optimal load distribution and stability, even under the most demanding operational stresses.</p>
              </div>
              <div className="service_page_card_small " data-aos="fade-right" data-aos-duration="1000">
                <h2>Storage Yard Construction</h2>
                <p>An effective storage yard is crucial to wind energy logistics. Our storage yards are meticulously planned to enhance material handling, maximize storage space, and shield components from environmental factors. This strategic approach ensures efficient and secure management of materials throughout the project lifecycle</p>
              </div>
              
            </div>
            <div className="service_page_card_image" data-aos="fade-up" data-aos-duration="1000">
              <img
                className="img-fluid"
                src={CivilWork}
                alt="land-sourcing" height="250px"
              />
            </div>
            <div className="service_page_card_content">
              <div className="service_page_card_small " data-aos="fade-right" data-aos-duration="1000">
                <h2>Transportation Infrastructure</h2>
                <p>Efficient transportation is a crucial factor for the success of a wind energy project. Our expertise lies in constructing robust, weather-resistant roads designed to support the movement of heavy components and equipment. This ensures smooth and continuous operations during the installation phase
                </p>
              </div>
              <div className="service_page_card_small " data-aos="fade-right" data-aos-duration="1000">
                <h2>PSS Construction</h2>
                <p>we specialize in the design, construction, and commissioning of pooling substations that meet the highest industry standards. Our team of experienced engineers and technicians are dedicated to delivering reliable and efficient electrical solutions for a diverse range of projects</p>
              </div>
            </div>
          </div>
        </Container>
      </div>
     </div>

    

       

     
    </div>
  );
};

export default ServicePageCard;
