import React, { useEffect } from 'react'
import AOS from 'aos';
import { motion } from "framer-motion";
import Banner_2 from '../../components/banner/Banner_2'
import Header from '../../components/header/Header'
import HeaderSeo from '../../components/head-seo/HeaderSeo'
import ContactInfo from '../../components/contact-info/ContactInfo'
import AboutTech from '../../components/aboutTech.jsx/AboutTech'
import OurHistory from '../../components/our-history/OurHistory'
import OurPresence from '../../components/our-presence/OurPresence'
import OurTeam from '../../components/our-core/OurTeam'
import JoinTeam from '../../components/our-join/JoinTeam'
import WhatWeDo from '../../components/what-we-do/WhatWeDo'
import WhyNimalaan from '../../components/why nimalaan/WhyNimalaan'
import Footer from '../../components/footer/Footer'
import { aboutBanner } from '../../utils/Banner'
import { AboutSeo } from '../../utils/Seo'
import './about.css'

const About = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(()=>{
    AOS.init({disable: 'mobile'});
  },[])

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
    >
         <HeaderSeo data={AboutSeo} />
         <Header/>
         <Banner_2 data= {aboutBanner} />
         <AboutTech />
         <WhatWeDo/>
         <WhyNimalaan/>
        
         <OurHistory/>
         <OurTeam/>
         {/* <JoinTeam/> */}
         <OurPresence/>
         {/* <ContactInfo /> */}
         <Footer />
    </motion.div>
  )
}

export default About;