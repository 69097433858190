import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { WhatWeDoImg } from "../../assets/images";

const WhatWeDo = () => {
  const firstRow = [
    {
      subtitle: "Project Planning",
      content:
        "Tailored strategies for the development and execution of successful wind and solar power projects",
    },
    {
      subtitle: "Development",
      content:
        "Our expertise lies in the seamless development of wind and solar power projects. We ensure every stage of your project is meticulously planned and executed.",
    },
    {
      subtitle: "Solutions",
      content:
        "Cutting-edge tools and techniques to maximize efficiency and sustainability in renewable energy productiona",
    },
    {
      subtitle: "Support & Maintenance",
      content:
        "Reliable support to keep your renewable energy projects running smoothly and efficiently.",
    },
  ];

  return (
    <div className="nimalaan-main-section card_sec mt-130">
      <Container>
        <Row className="">
          <Col lg={4} md={12} data-aos="fade-right" data-aos-duration="1000">
            <img
              className="img-fluid service"
              src={WhatWeDoImg}
              alt="what we do"
            />
          </Col>

          <Col lg={8} md={12}  data-aos="fade-left" data-aos-duration="1000" >
            <div className="our-achievements mt-30">
              <h2 className="image-content-card">What We Do</h2>
              <p className="image-card-content ">
              At Nimalaan, we are passionate about unlocking the potential of renewable energy. We provide a comprehensive range of services to support the development and implementation of successful wind and solar power projects. 
              </p>

             <Row className="mt-4">
                {firstRow.map((item, index) => {
                  return (
                    <Col key={item?.title} md={6} lg={6} className="mb-2">
                      <div className="whatwedo_card_about">
                        <h3 className="achievement_card_title">{item?.title}</h3>
                        <h4 className="achievement_card_subtitle">
                          {item?.subtitle}
                        </h4>
                        <p className="achievement_card_content">
                          {item?.content}
                        </p>
                      </div>
                    </Col>
                  );
                })}
              </Row> 
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default WhatWeDo;